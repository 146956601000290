@use "sass:math";
@import "ta-variables";

@font-face {
  font-family: 'ta-icons';
  src: url('#{$ta-font-path}/ta-icons.eot?22203896');
  src: url('#{$ta-font-path}/ta-icons.eot?22203896#iefix') format('embedded-opentype'),
       url('#{$ta-font-path}/ta-icons.woff2?22203896') format('woff2'),
       url('#{$ta-font-path}/ta-icons.woff?22203896') format('woff'),
       url('#{$ta-font-path}/ta-icons.ttf?22203896') format('truetype'),
       url('#{$ta-font-path}/ta-icons.svg?22203896#ta-icons') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'ta-icons';
    src: url('../fonts/ta-icons.svg?22203896#ta-icons') format('svg');
  }
}
*/

[class^="#{$ta-css-icon-prefix}-"]:before, [class*=" #{$ta-css-icon-prefix}-"]:before {
  font-family: "ta-icons";
  font-style: normal;
  font-weight: normal;
  speak: none;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.#{$ta-css-icon-prefix}-lg {
  font-size: math.div(4em, 3);
  line-height: math.div(3em, 4);
  vertical-align: -15%;
}
.#{$ta-css-icon-prefix}-2x { font-size: 2em; }
.#{$ta-css-icon-prefix}-3x { font-size: 3em; }
.#{$ta-css-icon-prefix}-4x { font-size: 4em; }
.#{$ta-css-icon-prefix}-5x { font-size: 5em; }

// Fixed Width Icons
// -------------------------
.#{$ta-css-icon-prefix}-fw {
  width: math.div(18em, 14);
  text-align: center;
}

// List Icons
// -------------------------

.#{$ta-css-icon-prefix}-ul {
  padding-left: 0;
  margin-left: $ta-li-width;
  list-style-type: none;
  > li { position: relative; }
}
.#{$ta-css-icon-prefix}-li {
  position: absolute;
  left: -$ta-li-width;
  width: $ta-li-width;
  top: math.div(2em, 14);
  text-align: center;
  &.#{$ta-css-icon-prefix}-lg {
    left: -$ta-li-width + math.div(4em, 14);
  }
}

// Bordered & Pulled
// -------------------------

.#{$ta-css-icon-prefix}-border {
  padding: .2em .25em .15em;
  border: solid .08em $ta-border-color;
  border-radius: .1em;
}

.#{$ta-css-icon-prefix}-pull-left { float: left; }
.#{$ta-css-icon-prefix}-pull-right { float: right; }

.#{$ta-css-icon-prefix} {
  &.#{$ta-css-icon-prefix}-pull-left { margin-right: .3em; }
  &.#{$ta-css-icon-prefix}-pull-right { margin-left: .3em; }
}

/* Deprecated as of 4.4.0 */
.pull-right { float: right; }
.pull-left { float: left; }

.#{$ta-css-icon-prefix} {
  &.pull-left { margin-right: .3em; }
  &.pull-right { margin-left: .3em; }
}

// Spinning Icons
// --------------------------

.#{$ta-css-icon-prefix}-spin {
  -webkit-animation: ta-spin 2s infinite linear;
  animation: ta-spin 2s infinite linear;
}

.#{$ta-css-icon-prefix}-pulse {
  -webkit-animation: ta-spin 1s infinite steps(8);
  animation: ta-spin 1s infinite steps(8);
}

@-webkit-keyframes ta-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@keyframes ta-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

.#{$ta-css-icon-prefix}-car:before { content: '\0e00'; } /* '฀' */
.#{$ta-css-icon-prefix}-truck:before { content: '\e001'; } /* '' */
.#{$ta-css-icon-prefix}-motorcycle:before { content: '\e002'; } /* '' */
.#{$ta-css-icon-prefix}-car-front:before { content: '\e003'; } /* '' */
.#{$ta-css-icon-prefix}-truck-front:before { content: '\e004'; } /* '' */
.#{$ta-css-icon-prefix}-motorcycle-front:before { content: '\e005'; } /* '' */
.#{$ta-css-icon-prefix}-engine:before { content: '\e006'; } /* '' */
.#{$ta-css-icon-prefix}-drive-train:before { content: '\e007'; } /* '' */
.#{$ta-css-icon-prefix}-steering:before { content: '\e008'; } /* '' */
.#{$ta-css-icon-prefix}-suspension:before { content: '\e009'; } /* '' */
.#{$ta-css-icon-prefix}-exhaust:before { content: '\e010'; } /* '' */
.#{$ta-css-icon-prefix}-brake:before { content: '\e011'; } /* '' */
.#{$ta-css-icon-prefix}-interior-equipment:before { content: '\e012'; } /* '' */
.#{$ta-css-icon-prefix}-fuel-mixture-formation:before { content: '\e013'; } /* '' */
.#{$ta-css-icon-prefix}-axle:before { content: '\e014'; } /* '' */
.#{$ta-css-icon-prefix}-window-cleaning:before { content: '\e015'; } /* '' */
.#{$ta-css-icon-prefix}-electrics:before { content: '\e016'; } /* '' */
.#{$ta-css-icon-prefix}-spark-glow-ignition:before { content: '\e017'; } /* '' */
.#{$ta-css-icon-prefix}-filter:before { content: '\e018'; } /* '' */
.#{$ta-css-icon-prefix}-lights:before { content: '\e019'; } /* '' */
.#{$ta-css-icon-prefix}-cooling-ac:before { content: '\e020'; } /* '' */
.#{$ta-css-icon-prefix}-chemical-products:before { content: '\e021'; } /* '' */
.#{$ta-css-icon-prefix}-special-tools:before { content: '\e022'; } /* '' */
.#{$ta-css-icon-prefix}-workshop-equipment:before { content: '\e023'; } /* '' */
.#{$ta-css-icon-prefix}-work-protection:before { content: '\e024'; } /* '' */
.#{$ta-css-icon-prefix}-accessories:before { content: '\e025'; } /* '' */
.#{$ta-css-icon-prefix}-motorcycle-special-tool:before { content: '\e026'; } /* '' */
.#{$ta-css-icon-prefix}-tools:before { content: '\e027'; } /* '' */
.#{$ta-css-icon-prefix}-trailer-hitch:before { content: '\e028'; } /* '' */
.#{$ta-css-icon-prefix}-maintenance-service:before { content: '\e029'; } /* '' */
.#{$ta-css-icon-prefix}-body-truck:before { content: '\e030'; } /* '' */
.#{$ta-css-icon-prefix}-body-car:before { content: '\e031'; } /* '' */
.#{$ta-css-icon-prefix}-body-motorcycle:before { content: '\e032'; } /* '' */
.#{$ta-css-icon-prefix}-fitting-position-front-left:before { content: '\e033'; } /* '' */
.#{$ta-css-icon-prefix}-fitting-position-front-right:before { content: '\e034'; } /* '' */
.#{$ta-css-icon-prefix}-fitting-position-rear-left:before { content: '\e035'; } /* '' */
.#{$ta-css-icon-prefix}-fitting-position-rear-right-1:before { content: '\e036'; } /* '' */
.#{$ta-css-icon-prefix}-fitting-position-rear-right:before { content: '\e037'; } /* '' */
.#{$ta-css-icon-prefix}-fitting-position-front:before { content: '\e038'; } /* '' */
.#{$ta-css-icon-prefix}-fitting-position-all:before { content: '\e039'; } /* '' */
.#{$ta-css-icon-prefix}-repair-time:before { content: '\e040'; } /* '' */
.#{$ta-css-icon-prefix}-technical-manuals:before { content: '\e041'; } /* '' */
.#{$ta-css-icon-prefix}-technical-data:before { content: '\e042'; } /* '' */
.#{$ta-css-icon-prefix}-diagnostic-data:before { content: '\e043'; } /* '' */
.#{$ta-css-icon-prefix}-wiring-diagrams:before { content: '\e044'; } /* '' */
.#{$ta-css-icon-prefix}-fuse-relay:before { content: '\e046'; } /* '' */
.#{$ta-css-icon-prefix}-paintwork:before { content: '\e047'; } /* '' */
.#{$ta-css-icon-prefix}-copy:before { content: '\e048'; } /* '' */
.#{$ta-css-icon-prefix}-heating-ac:before { content: '\e049'; } /* '' */
.#{$ta-css-icon-prefix}-transmission:before { content: '\e050'; } /* '' */
.#{$ta-css-icon-prefix}-steering-suspension:before { content: '\e051'; } /* '' */
.#{$ta-css-icon-prefix}-exterior-interior-truck:before { content: '\e052'; } /* '' */
.#{$ta-css-icon-prefix}-exterior-interior-car:before { content: '\e053'; } /* '' */
.#{$ta-css-icon-prefix}-light-commercial-vehicle:before { content: '\e054'; } /* '' */
.#{$ta-css-icon-prefix}-user:before { content: '\e055'; } /* '' */
.#{$ta-css-icon-prefix}-user-log:before { content: '\e056'; } /* '' */
.#{$ta-css-icon-prefix}-key:before { content: '\e057'; } /* '' */
.#{$ta-css-icon-prefix}-no-image:before { content: '\e058'; } /* '' */
.#{$ta-css-icon-prefix}-camera:before { content: '\e059'; } /* '' */
.#{$ta-css-icon-prefix}-manufactor:before { content: '\e060'; } /* '' */
.#{$ta-css-icon-prefix}-dealer:before { content: '\e061'; } /* '' */
.#{$ta-css-icon-prefix}-save:before { content: '\e062'; } /* '' */
.#{$ta-css-icon-prefix}-visible:before { content: '\e063'; } /* '' */
.#{$ta-css-icon-prefix}-invisible:before { content: '\e064'; } /* '' */
.#{$ta-css-icon-prefix}-criteria:before { content: '\e065'; } /* '' */
.#{$ta-css-icon-prefix}-shopping-cart:before { content: '\e066'; } /* '' */
.#{$ta-css-icon-prefix}-add-cart:before { content: '\e067'; } /* '' */
.#{$ta-css-icon-prefix}-remove-cart:before { content: '\e068'; } /* '' */
.#{$ta-css-icon-prefix}-search-by-car:before { content: '\e069'; } /* '' */
.#{$ta-css-icon-prefix}-search-by-truck:before { content: '\e070'; } /* '' */
.#{$ta-css-icon-prefix}-search-by-number:before { content: '\e071'; } /* '' */
.#{$ta-css-icon-prefix}-search-by-engine:before { content: '\e072'; } /* '' */
.#{$ta-css-icon-prefix}-id-check:before { content: '\e073'; } /* '' */
.#{$ta-css-icon-prefix}-scan-barcode:before { content: '\e074'; } /* '' */
.#{$ta-css-icon-prefix}-link:before { content: '\e075'; } /* '' */
.#{$ta-css-icon-prefix}-search:before { content: '\e076'; } /* '' */
.#{$ta-css-icon-prefix}-home:before { content: '\e077'; } /* '' */
.#{$ta-css-icon-prefix}-edit:before { content: '\e078'; } /* '' */
.#{$ta-css-icon-prefix}-delete:before { content: '\e079'; } /* '' */
.#{$ta-css-icon-prefix}-master-data:before { content: '\e080'; } /* '' */
.#{$ta-css-icon-prefix}-master-data-full:before { content: '\e081'; } /* '' */
.#{$ta-css-icon-prefix}-master-data-half:before { content: '\e082'; } /* '' */
.#{$ta-css-icon-prefix}-master-data-add:before { content: '\e083'; } /* '' */
.#{$ta-css-icon-prefix}-master-data-price:before { content: '\e084'; } /* '' */
.#{$ta-css-icon-prefix}-document-finished:before { content: '\e085'; } /* '' */
.#{$ta-css-icon-prefix}-document-delivered:before { content: '\e086'; } /* '' */
.#{$ta-css-icon-prefix}-configuration:before { content: '\e087'; } /* '' */
.#{$ta-css-icon-prefix}-microphone:before { content: '\e088'; } /* '' */
.#{$ta-css-icon-prefix}-dashboard:before { content: '\e089'; } /* '' */
.#{$ta-css-icon-prefix}-wheels-tyres:before { content: '\e090'; } /* '' */
.#{$ta-css-icon-prefix}-general:before { content: '\e091'; } /* '' */
.#{$ta-css-icon-prefix}-main-group:before { content: '\e092'; } /* '' */
.#{$ta-css-icon-prefix}-subgroup:before { content: '\e093'; } /* '' */
.#{$ta-css-icon-prefix}-process:before { content: '\e094'; } /* '' */
.#{$ta-css-icon-prefix}-clock:before { content: '\e095'; } /* '' */
.#{$ta-css-icon-prefix}-repair:before { content: '\e096'; } /* '' */
.#{$ta-css-icon-prefix}-thumbs-down:before { content: '\e097'; } /* '' */
.#{$ta-css-icon-prefix}-thumbs-up:before { content: '\e098'; } /* '' */
.#{$ta-css-icon-prefix}-mail-alt:before { content: '\e101'; } /* '' */
.#{$ta-css-icon-prefix}-star:before { content: '\e102'; } /* '' */
.#{$ta-css-icon-prefix}-star-empty:before { content: '\e103'; } /* '' */
.#{$ta-css-icon-prefix}-th-large:before { content: '\e104'; } /* '' */
.#{$ta-css-icon-prefix}-th:before { content: '\e105'; } /* '' */
.#{$ta-css-icon-prefix}-th-list:before { content: '\e106'; } /* '' */
.#{$ta-css-icon-prefix}-ok:before { content: '\e107'; } /* '' */
.#{$ta-css-icon-prefix}-ok-circled:before { content: '\e108'; } /* '' */
.#{$ta-css-icon-prefix}-ok-squared:before { content: '\e109'; } /* '' */
.#{$ta-css-icon-prefix}-cancel:before { content: '\e110'; } /* '' */
.#{$ta-css-icon-prefix}-cancel-circled:before { content: '\e111'; } /* '' */
.#{$ta-css-icon-prefix}-window-close:before { content: '\e112'; } /* '' */
.#{$ta-css-icon-prefix}-plus:before { content: '\e113'; } /* '' */
.#{$ta-css-icon-prefix}-plus-circled:before { content: '\e114'; } /* '' */
.#{$ta-css-icon-prefix}-plus-squared:before { content: '\e115'; } /* '' */
.#{$ta-css-icon-prefix}-plus-squared-alt:before { content: '\e116'; } /* '' */
.#{$ta-css-icon-prefix}-minus:before { content: '\e117'; } /* '' */
.#{$ta-css-icon-prefix}-minus-circled:before { content: '\e118'; } /* '' */
.#{$ta-css-icon-prefix}-minus-squared:before { content: '\e119'; } /* '' */
.#{$ta-css-icon-prefix}-minus-squared-alt:before { content: '\e120'; } /* '' */
.#{$ta-css-icon-prefix}-help-circled:before { content: '\e121'; } /* '' */
.#{$ta-css-icon-prefix}-info-circled:before { content: '\e122'; } /* '' */
.#{$ta-css-icon-prefix}-lock:before { content: '\e123'; } /* '' */
.#{$ta-css-icon-prefix}-lock-open-alt:before { content: '\e124'; } /* '' */
.#{$ta-css-icon-prefix}-tag:before { content: '\e125'; } /* '' */
.#{$ta-css-icon-prefix}-tags:before { content: '\e126'; } /* '' */
.#{$ta-css-icon-prefix}-download-cloud:before { content: '\e127'; } /* '' */
.#{$ta-css-icon-prefix}-upload-cloud:before { content: '\e128'; } /* '' */
.#{$ta-css-icon-prefix}-bell-alt:before { content: '\e129'; } /* '' */
.#{$ta-css-icon-prefix}-attention-alt:before { content: '\e130'; } /* '' */
.#{$ta-css-icon-prefix}-attention:before { content: '\e131'; } /* '' */
.#{$ta-css-icon-prefix}-attention-circled:before { content: '\e132'; } /* '' */
.#{$ta-css-icon-prefix}-doc-inv:before { content: '\e133'; } /* '' */
.#{$ta-css-icon-prefix}-doc-text-inv:before { content: '\e134'; } /* '' */
.#{$ta-css-icon-prefix}-file-pdf:before { content: '\e135'; } /* '' */
.#{$ta-css-icon-prefix}-file-word:before { content: '\e136'; } /* '' */
.#{$ta-css-icon-prefix}-file-excel:before { content: '\e137'; } /* '' */
.#{$ta-css-icon-prefix}-file-image:before { content: '\e138'; } /* '' */
.#{$ta-css-icon-prefix}-picture:before { content: '\e139'; } /* '' */
.#{$ta-css-icon-prefix}-folder:before { content: '\e140'; } /* '' */
.#{$ta-css-icon-prefix}-rss:before { content: '\e141'; } /* '' */
.#{$ta-css-icon-prefix}-rss-squared:before { content: '\e142'; } /* '' */
.#{$ta-css-icon-prefix}-phone:before { content: '\e143'; } /* '' */
.#{$ta-css-icon-prefix}-cog:before { content: '\e144'; } /* '' */
.#{$ta-css-icon-prefix}-cog-alt:before { content: '\e145'; } /* '' */
.#{$ta-css-icon-prefix}-down-open:before { content: '\e146'; } /* '' */
.#{$ta-css-icon-prefix}-up-open:before { content: '\e147'; } /* '' */
.#{$ta-css-icon-prefix}-left-open:before { content: '\e148'; } /* '' */
.#{$ta-css-icon-prefix}-right-open:before { content: '\e149'; } /* '' */
.#{$ta-css-icon-prefix}-down-dir:before { content: '\e150'; } /* '' */
.#{$ta-css-icon-prefix}-up-dir:before { content: '\e151'; } /* '' */
.#{$ta-css-icon-prefix}-left-dir:before { content: '\e152'; } /* '' */
.#{$ta-css-icon-prefix}-right-dir:before { content: '\e153'; } /* '' */
.#{$ta-css-icon-prefix}-arrows-cw:before { content: '\e154'; } /* '' */
.#{$ta-css-icon-prefix}-shuffle:before { content: '\e155'; } /* '' */
.#{$ta-css-icon-prefix}-expand:before { content: '\e156'; } /* '' */
.#{$ta-css-icon-prefix}-collapse:before { content: '\e157'; } /* '' */
.#{$ta-css-icon-prefix}-expand-right:before { content: '\e158'; } /* '' */
.#{$ta-css-icon-prefix}-collapse-left:before { content: '\e159'; } /* '' */
.#{$ta-css-icon-prefix}-align-left:before { content: '\e160'; } /* '' */
.#{$ta-css-icon-prefix}-align-center:before { content: '\e161'; } /* '' */
.#{$ta-css-icon-prefix}-align-right:before { content: '\e162'; } /* '' */
.#{$ta-css-icon-prefix}-align-justify:before { content: '\e163'; } /* '' */
.#{$ta-css-icon-prefix}-list:before { content: '\e164'; } /* '' */
.#{$ta-css-icon-prefix}-indent-left:before { content: '\e165'; } /* '' */
.#{$ta-css-icon-prefix}-indent-right:before { content: '\e166'; } /* '' */
.#{$ta-css-icon-prefix}-list-bullet:before { content: '\e167'; } /* '' */
.#{$ta-css-icon-prefix}-chart-bar:before { content: '\e168'; } /* '' */
.#{$ta-css-icon-prefix}-chart-area:before { content: '\e169'; } /* '' */
.#{$ta-css-icon-prefix}-chart-pie:before { content: '\e170'; } /* '' */
.#{$ta-css-icon-prefix}-youtube-squared:before { content: '\e171'; } /* '' */
.#{$ta-css-icon-prefix}-youtube-play:before { content: '\e172'; } /* '' */
.#{$ta-css-icon-prefix}-twitter-squared:before { content: '\e173'; } /* '' */
.#{$ta-css-icon-prefix}-twitter:before { content: '\e174'; } /* '' */
.#{$ta-css-icon-prefix}-linkedin-squared:before { content: '\e175'; } /* '' */
.#{$ta-css-icon-prefix}-linkedin:before { content: '\e176'; } /* '' */
.#{$ta-css-icon-prefix}-facebook:before { content: '\e177'; } /* '' */
.#{$ta-css-icon-prefix}-facebook-squared:before { content: '\e178'; } /* '' */
.#{$ta-css-icon-prefix}-trademark:before { content: '\e179'; } /* '' */
.#{$ta-css-icon-prefix}-registered:before { content: '\e180'; } /* '' */
.#{$ta-css-icon-prefix}-sort-alt-up:before { content: '\e182'; } /* '' */
.#{$ta-css-icon-prefix}-sort-alt-down:before { content: '\e183'; } /* '' */
.#{$ta-css-icon-prefix}-sort:before { content: '\e184'; } /* '' */
.#{$ta-css-icon-prefix}-sort-down:before { content: '\e185'; } /* '' */
.#{$ta-css-icon-prefix}-sort-up:before { content: '\e186'; } /* '' */
.#{$ta-css-icon-prefix}-check:before { content: '\e187'; } /* '' */
.#{$ta-css-icon-prefix}-check-empty:before { content: '\e188'; } /* '' */
.#{$ta-css-icon-prefix}-toggle-off:before { content: '\e189'; } /* '' */
.#{$ta-css-icon-prefix}-toggle-on:before { content: '\e190'; } /* '' */
.#{$ta-css-icon-prefix}-circle-empty:before { content: '\e191'; } /* '' */
.#{$ta-css-icon-prefix}-circle:before { content: '\e192'; } /* '' */
.#{$ta-css-icon-prefix}-adjust:before { content: '\e193'; } /* '' */
.#{$ta-css-icon-prefix}-logout:before { content: '\e194'; } /* '' */
.#{$ta-css-icon-prefix}-login:before { content: '\e195'; } /* '' */
.#{$ta-css-icon-prefix}-location:before { content: '\e196'; } /* '' */
.#{$ta-css-icon-prefix}-sliders:before { content: '\e197'; } /* '' */
.#{$ta-css-icon-prefix}-xing:before { content: '\e198'; } /* '' */
.#{$ta-css-icon-prefix}-xing-squared:before { content: '\e199'; } /* '' */
.#{$ta-css-icon-prefix}-signal:before { content: '\e200'; } /* '' */
.#{$ta-css-icon-prefix}-belt-drive:before { content: '\e201'; } /* '' */
.#{$ta-css-icon-prefix}-clutch-parts:before { content: '\e202'; } /* '' */
.#{$ta-css-icon-prefix}-carrier-equipment:before { content: '\e203'; } /* '' */
.#{$ta-css-icon-prefix}-comfort-systems:before { content: '\e204'; } /* '' */
.#{$ta-css-icon-prefix}-compressed-air-system:before { content: '\e205'; } /* '' */
.#{$ta-css-icon-prefix}-headlight-washer-system:before { content: '\e206'; } /* '' */
.#{$ta-css-icon-prefix}-electric-drive:before { content: '\e207'; } /* '' */
.#{$ta-css-icon-prefix}-hybrid:before { content: '\e208'; } /* '' */
.#{$ta-css-icon-prefix}-axle-drive:before { content: '\e209'; } /* '' */
.#{$ta-css-icon-prefix}-air-conditioning:before { content: '\e210'; } /* '' */
.#{$ta-css-icon-prefix}-operating-fluids-:before { content: '\e211'; } /* '' */
.#{$ta-css-icon-prefix}-locking-system:before { content: '\e212'; } /* '' */
.#{$ta-css-icon-prefix}-communication-systems:before { content: '\e213'; } /* '' */
.#{$ta-css-icon-prefix}-maintenance-service-parts:before { content: '\e214'; } /* '' */
.#{$ta-css-icon-prefix}-security-systems:before { content: '\e215'; } /* '' */
.#{$ta-css-icon-prefix}-heater:before { content: '\e216'; } /* '' */
.#{$ta-css-icon-prefix}-towbar-parts:before { content: '\e217'; } /* '' */
.#{$ta-css-icon-prefix}-wheel-drive:before { content: '\e218'; } /* '' */
.#{$ta-css-icon-prefix}-vin-number:before { content: '\e219'; } /* '' */
.#{$ta-css-icon-prefix}-skype:before { content: '\e220'; } /* '' */
.#{$ta-css-icon-prefix}-gplus:before { content: '\e221'; } /* '' */
.#{$ta-css-icon-prefix}-gplus-squared:before { content: '\e222'; } /* '' */
.#{$ta-css-icon-prefix}-calendar:before { content: '\e223'; } /* '' */
.#{$ta-css-icon-prefix}-calendar-plus-o:before { content: '\e224'; } /* '' */
.#{$ta-css-icon-prefix}-calendar-minus-o:before { content: '\e225'; } /* '' */
.#{$ta-css-icon-prefix}-calendar-times-o:before { content: '\e226'; } /* '' */
.#{$ta-css-icon-prefix}-calendar-check-o:before { content: '\e227'; } /* '' */
.#{$ta-css-icon-prefix}-route:before { content: '\e228'; } /* '' */
.#{$ta-css-icon-prefix}-earth:before { content: '\e229'; } /* '' */
.#{$ta-css-icon-prefix}-warranty:before { content: '\e230'; } /* '' */
.#{$ta-css-icon-prefix}-item-part-number:before { content: '\e231'; } /* '' */
.#{$ta-css-icon-prefix}-comparable-number:before { content: '\e232'; } /* '' */
.#{$ta-css-icon-prefix}-oe-number:before { content: '\e233'; } /* '' */
.#{$ta-css-icon-prefix}-trade-number:before { content: '\e234'; } /* '' */
.#{$ta-css-icon-prefix}-comment:before { content: '\e235'; } /* '' */
.#{$ta-css-icon-prefix}-chat:before { content: '\e236'; } /* '' */
.#{$ta-css-icon-prefix}-filtering:before { content: '\e237'; } /* '' */
.#{$ta-css-icon-prefix}-cw:before { content: '\e238'; } /* '' */
.#{$ta-css-icon-prefix}-print:before { content: '\e239'; } /* '' */
.#{$ta-css-icon-prefix}-fuel-supply-system:before { content: '\e240'; } /* '' */
.#{$ta-css-icon-prefix}-universal-part:before { content: '\e241'; } /* '' */
.#{$ta-css-icon-prefix}-gtin-number:before { content: '\e242'; } /* '' */
.#{$ta-css-icon-prefix}-360-view:before { content: '\e243'; } /* '' */
.#{$ta-css-icon-prefix}-price:before { content: '\e244'; } /* '' */
.#{$ta-css-icon-prefix}-calculator:before { content: '\e245'; } /* '' */
.#{$ta-css-icon-prefix}-open-window:before { content: '\e246'; } /* '' */
.#{$ta-css-icon-prefix}-resize-full-alt:before { content: '\e247'; } /* '' */
.#{$ta-css-icon-prefix}-upload:before { content: '\e248'; } /* '' */
.#{$ta-css-icon-prefix}-steering-linkage:before { content: '\e249'; } /* '' */
.#{$ta-css-icon-prefix}-power-take-off:before { content: '\e250'; } /* '' */
.#{$ta-css-icon-prefix}-crank-drive:before { content: '\e251'; } /* '' */
.#{$ta-css-icon-prefix}-crank-case:before { content: '\e252'; } /* '' */
.#{$ta-css-icon-prefix}-hydraulic-system:before { content: '\e253'; } /* '' */
.#{$ta-css-icon-prefix}-mileage:before { content: '\e254'; } /* '' */
.#{$ta-css-icon-prefix}-handshake:before { content: '\e255'; } /* '' */
.#{$ta-css-icon-prefix}-gaskets:before { content: '\e256'; } /* '' */
.#{$ta-css-icon-prefix}-fifth-wheel-coupling:before { content: '\e257'; } /* '' */
.#{$ta-css-icon-prefix}-load-securing:before { content: '\e258'; } /* '' */
.#{$ta-css-icon-prefix}-cylinder-heads:before { content: '\e259'; } /* '' */
.#{$ta-css-icon-prefix}-piston:before { content: '\e260'; } /* '' */
.#{$ta-css-icon-prefix}-crankshaft:before { content: '\e261'; } /* '' */
.#{$ta-css-icon-prefix}-camshaft:before { content: '\e262'; } /* '' */
.#{$ta-css-icon-prefix}-water-pump:before { content: '\e263'; } /* '' */
.#{$ta-css-icon-prefix}-engine-mounting:before { content: '\e264'; } /* '' */
.#{$ta-css-icon-prefix}-lubrication:before { content: '\e265'; } /* '' */
.#{$ta-css-icon-prefix}-air-filter:before { content: '\e266'; } /* '' */
.#{$ta-css-icon-prefix}-oxygen-sensor:before { content: '\e267'; } /* '' */
.#{$ta-css-icon-prefix}-tuning:before { content: '\e268'; } /* '' */
.#{$ta-css-icon-prefix}-bolt-nut:before { content: '\e269'; } /* '' */
.#{$ta-css-icon-prefix}-instruments:before { content: '\e270'; } /* '' */
.#{$ta-css-icon-prefix}-mirror:before { content: '\e271'; } /* '' */
.#{$ta-css-icon-prefix}-download:before { content: '\e272'; } /* '' */
.#{$ta-css-icon-prefix}-fleet:before { content: '\e273'; } /* '' */
.#{$ta-css-icon-prefix}-workshop:before { content: '\e274'; } /* '' */
.#{$ta-css-icon-prefix}-angle-double-left:before { content: '\e275'; } /* '' */
.#{$ta-css-icon-prefix}-angle-double-right:before { content: '\e276'; } /* '' */
.#{$ta-css-icon-prefix}-angle-double-up:before { content: '\e277'; } /* '' */
.#{$ta-css-icon-prefix}-angle-double-down:before { content: '\e278'; } /* '' */
.#{$ta-css-icon-prefix}-resize-small:before { content: '\e280'; } /* '' */
.#{$ta-css-icon-prefix}-resize-full:before { content: '\e281'; } /* '' */
.#{$ta-css-icon-prefix}-ean-number:before { content: '\e282'; } /* '' */
.#{$ta-css-icon-prefix}-marketplace:before { content: '\e283'; } /* '' */
.#{$ta-css-icon-prefix}-down-big:before { content: '\e284'; } /* '' */
.#{$ta-css-icon-prefix}-up-big:before { content: '\e285'; } /* '' */
.#{$ta-css-icon-prefix}-left-big:before { content: '\e286'; } /* '' */
.#{$ta-css-icon-prefix}-right-big:before { content: '\e287'; } /* '' */
.#{$ta-css-icon-prefix}-share:before { content: '\e288'; } /* '' */
.#{$ta-css-icon-prefix}-chart-line:before { content: '\e289'; } /* '' */
.#{$ta-css-icon-prefix}-translate:before { content: '\e290'; } /* '' */
.#{$ta-css-icon-prefix}-tecdoc-type-number:before { content: '\e291'; } /* '' */
.#{$ta-css-icon-prefix}-support:before { content: '\e292'; } /* '' */
.#{$ta-css-icon-prefix}-products:before { content: '\e293'; } /* '' */
.#{$ta-css-icon-prefix}-recommended:before { content: '\e294'; } /* '' */
.#{$ta-css-icon-prefix}-cv-and-pc:before { content: '\e295'; } /* '' */
.#{$ta-css-icon-prefix}-lcv-and-pc:before { content: '\e296'; } /* '' */
.#{$ta-css-icon-prefix}-ellipsis:before { content: '\e297'; } /* '' */
.#{$ta-css-icon-prefix}-ellipsis-vert:before { content: '\e298'; } /* '' */
.#{$ta-css-icon-prefix}-to-do-list:before { content: '\e299'; } /* '' */
.#{$ta-css-icon-prefix}-vehicle-coverage:before { content: '\e300'; } /* '' */
.#{$ta-css-icon-prefix}-bookmark:before { content: '\e301'; } /* '' */
.#{$ta-css-icon-prefix}-bookmark-empty:before { content: '\e302'; } /* '' */
.#{$ta-css-icon-prefix}-star-half-alt:before { content: '\e303'; } /* '' */
.#{$ta-css-icon-prefix}-newspaper:before { content: '\e304'; } /* '' */
.#{$ta-css-icon-prefix}-add-user:before { content: '\e305'; } /* '' */
.#{$ta-css-icon-prefix}-remove-user:before { content: '\e306'; } /* '' */
.#{$ta-css-icon-prefix}-pin:before { content: '\e307'; } /* '' */
.#{$ta-css-icon-prefix}-wifi:before { content: '\e308'; } /* '' */
.#{$ta-css-icon-prefix}-sitemap:before { content: '\e309'; } /* '' */
.#{$ta-css-icon-prefix}-windows:before { content: '\e310'; } /* '' */
.#{$ta-css-icon-prefix}-apple:before { content: '\e311'; } /* '' */
.#{$ta-css-icon-prefix}-amazon:before { content: '\e312'; } /* '' */
.#{$ta-css-icon-prefix}-chrome:before { content: '\e313'; } /* '' */
.#{$ta-css-icon-prefix}-firefox:before { content: '\e314'; } /* '' */
.#{$ta-css-icon-prefix}-internet-explorer:before { content: '\e315'; } /* '' */
.#{$ta-css-icon-prefix}-edge:before { content: '\e316'; } /* '' */
.#{$ta-css-icon-prefix}-android:before { content: '\e317'; } /* '' */
.#{$ta-css-icon-prefix}-graduation-cap:before { content: '\e318'; } /* '' */
.#{$ta-css-icon-prefix}-tecalliance-cube:before { content: '\e8a8'; } /* '' */
.#{$ta-css-icon-prefix}-instagram:before { content: '\e319'; }
.#{$ta-css-icon-prefix}-cc-paypal:before { content: '\e320'; }
.#{$ta-css-icon-prefix}-cc-visa:before { content: '\e321'; }
.#{$ta-css-icon-prefix}-trello:before { content: '\e322'; }
.#{$ta-css-icon-prefix}-bluetooth:before { content: '\e323'; }
.#{$ta-css-icon-prefix}-cc:before { content: '\e324'; }
.#{$ta-css-icon-prefix}-dropbox:before { content: '\e325'; }
.#{$ta-css-icon-prefix}-angle-left:before { content: '\e326'; }
.#{$ta-css-icon-prefix}-angle-right:before { content: '\e327'; }
.#{$ta-css-icon-prefix}-angle-up:before { content: '\e328'; }
.#{$ta-css-icon-prefix}-angle-down:before { content: '\e329'; }
.#{$ta-css-icon-prefix}-close-alt:before { content: '\e330'; }
.#{$ta-css-icon-prefix}-history:before { content: '\e331'; }
.#{$ta-css-icon-prefix}-cart-history:before { content: '\e332'; }
.#{$ta-css-icon-prefix}-megaphone:before { content: '\e333'; }
.#{$ta-css-icon-prefix}-unlink-alt:before { content: '\e334'; }
.#{$ta-css-icon-prefix}-link-alt:before { content: '\e335'; }
.#{$ta-css-icon-prefix}-drag-drop:before { content: '\e336'; }
.#{$ta-css-icon-prefix}-back:before { content: '\e337'; }
.#{$ta-css-icon-prefix}-forward:before { content: '\e338'; }
.#{$ta-css-icon-prefix}-undo:before { content: '\e339'; }
.#{$ta-css-icon-prefix}-redo:before { content: '\e340'; }
.#{$ta-css-icon-prefix}-idp:before { content: '\e341'; }
.#{$ta-css-icon-prefix}-save-close:before { content: '\e342'; }
.#{$ta-css-icon-prefix}-no-filtering:before { content: '\e343'; }
.#{$ta-css-icon-prefix}-add-column:before { content: '\e344'; }
.#{$ta-css-icon-prefix}-delete-column:before { content: '\e345'; }
.#{$ta-css-icon-prefix}-add-row:before { content: '\e346'; }
.#{$ta-css-icon-prefix}-delete-row:before { content: '\e347'; }
