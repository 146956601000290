.img-welcome {
    background: transparent
      url(../assets/images/product-tour/illu_welcome.png) 0% 0% no-repeat
      padding-box;
    width: 147px;
    min-width: 147px;
    height: 131px;
  }

  .img-drilldown {
    background: transparent
      url(../assets/images/product-tour/illu-drill-down-info.png) 0% 0%
      no-repeat padding-box;
    width: 147px;
    min-width: 147px;
    height: 131px;
  }

  .img-complete {
    background: transparent
      url(../assets/images/product-tour/illu-complete.png) 0% 0% no-repeat
      padding-box;
    width: 147px;
    min-width: 147px;
    height: 131px;
  }

div.tour-text{
  $tour-header-bg-color: $ta-panel-header-background-color;

  p.tour-font-normal {
    font: normal normal normal 15px/20px $ta-font;
    i::before {
      margin-left: 0 !important;
    }
    i {
      &.ta-icon-clock {
        color: $ta-primary-color;
      }
      &.ta-icon-tags {
        color: $ta-four-color;
      }
      &.ta-icon-location {
        color: $ta-text-color;
      }
      &.ta-icon-master-data {
        color: #6e1868;
      }
      &.ta-icon-cw {
        color: $ta-info-hover-color;
      }
    }
  }

  p.tour-font-title {
    font: normal normal bold 17px/20px $ta-font !important;
  }
}

.tour-popover-no-arrow {
  .popover-arrow {
    display: none;
  }
}
