@import "ta-variables";

.p-dropdown, .p-dropdown-panel, .p-overlay-content {
  &.items-no-padding {
      .p-dropdown-items {
        padding: 0 !important;
        margin: 0 !important;
    }
  }
}

.p-overlay-content > .p-multiselect-panel {
  &.items-no-padding {
    .p-multiselect-items {
      padding: 0 !important;
      margin: 0 !important;
    }
  }

  &.groups-as-dividers {
    .p-multiselect-item-group {
      height: 5px !important;
      padding: 0 !important;
      background: lightgray;

      &:first-of-type {
        display: none;
      }
    }
  }

  &.xs {
    .p-multiselect-header {
      padding-top: 0.25rem;
      padding-bottom: 0.25rem;

      .p-multiselect-filter-container {
        .p-multiselect-filter {
          padding-top: 0;
          padding-bottom: 0;
          font-size: 14px;
        }

        .p-icon-wrapper {
          .p-icon {
            height: 14px;
            width: 14px;
            margin-top: -7px;
            margin-right: -2px;
          }
        }
      }
    }

    .p-multiselect-items {
      font-size: 14px;
    }
  }
}

.p-multiselect {
  &.xs {
    height: 25px;
    font-size: 14px;

    .p-multiselect-label {
      padding-top: 0;
      padding-bottom: 0;
    }

    .p-multiselect-trigger {
      width: 25px;
    }
  }

  &.analytics-filter {
    border-radius: 0;

    .p-multiselect-trigger {
      background-color: $gray-300;
      border-left: 1px solid $gray-500;
    }
  }
}

.p-dropdown {
  &.xs {
    height: 25px;
    font-size: 14px;

    .p-dropdown-label {
      padding-top: 0;
      padding-bottom: 0;
      font-size: 14px;
    }

    .p-dropdown-trigger {
      width: 25px;
    }
  }

  &.analytics-filter {
    border-radius: 0;

    .p-dropdown-trigger {
      background-color: $gray-300;
      border-left: 1px solid $gray-500;
    }
  }

  .p-overlay {
    .p-overlay-content {
      .p-dropdown-panel {
        &.xs {
          .p-dropdown-items {
            font-size: 14px;
          }
        }
      }
    }
  }
}

.p-overlay {
  .p-overlay-content {
    .p-dropdown-panel {
      &.xs {
        .p-dropdown-header {
          padding-top: 0.25rem;
          padding-bottom: 0.25rem;

          .p-dropdown-filter-container {
            .p-dropdown-filter {
              padding-top: 0;
              padding-bottom: 0;
              font-size: 14px;
            }

            .p-icon-wrapper {
              .p-icon {
                height: 14px;
                width: 14px;
                margin-top: -7px;
                margin-right: -2px;
              }
            }
          }
        }

        .p-dropdown-items {
          font-size: 14px;
        }
      }
    }
  }
}

.p-datepicker {
  max-width: 300px;
  font-size: 14px;

  .p-inputwrapper {
    .p-dropdown {
      &.analytics-filter {
        &.xs {
          height: 25px;
          font-size: 14px;

          .p-dropdown-label {
            padding-top: 0;
            padding-bottom: 0;
            font-size: 14px;
          }

          .p-dropdown-trigger {
            width: 25px;
          }
        }

        border-radius: 0;

        .p-dropdown-trigger {
          background-color: $gray-300;
          border-left: 1px solid $gray-500;
        }
      }

      .p-overlay {
        .p-overlay-content {
          .p-dropdown-panel {
            &.xs {
              .p-dropdown-items {
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }

  .p-datepicker-group-container {
    .p-datepicker-group {
      .p-datepicker-header {
        border-bottom: 1px solid $gray-300;
        padding-bottom: 0;
        margin-bottom: 10px;
      }

      .p-datepicker-calendar-container {
        table.p-datepicker-calendar {
          display: flex;
          flex-direction: column;
          max-width: 300px;
          margin: 0;

          thead, tbody {
            tr {
              display: flex;
              justify-content: space-around;

              th, td {
                padding: 0;

                span {
                  width: 2rem;
                  height: 2rem;
                }
              }
            }
          }


        }
      }
    }
  }
}

.p-calendar {
  &.analytics-filter {
    &.xs {
      height: 25px;
      font-size: 14px;
    }

    border-radius: 0;
    cursor: pointer;

    .p-inputtext {
      border-radius: 0;
      cursor: pointer;
    }
  }
}

.p-menu, .p-overlay-content {
  &.items-no-padding {
    .p-menu-list {
      padding: 0 !important;
      margin: 0 !important;
    }
  }

  &.xs {
    .p-menu-list {
      .p-menuitem {
        &:first-of-type {
          border-bottom: 1px solid $gray-500;
        }

        .p-menuitem-content {
          font-size: 14px;
        }
      }
    }
  }

  &.text-decoration-none {
    .p-menu-list {
      .p-menuitem {
        .p-menuitem-link {
          text-decoration: none;
        }
      }
    }
  }
}

.p-autocomplete, .p-overlay-content {
  &.items-no-padding {
    .p-autocomplete-multiple-container {
      padding: 0 0.25rem !important;
      margin: 0 !important;
    }

    .p-autocomplete-items {
      padding: 0 !important;
      margin: 0 !important;
    }
  }

  &.flex-grow-input {
    .p-autocomplete-multiple-container {
      display: flex;
      flex-grow: 1;
    }
  }

  &.autocomplete-token-ta-colors {
    .p-autocomplete-token {
      background: $ta-cta-bg 0% 0% no-repeat padding-box !important;
    }
  }

  .p-autocomplete-token-icon {
    margin-left: 0.2rem;
  }
}

.p-listbox-list-wrapper {
  &.items-no-padding {
    .p-listbox-list {
      padding: 0 !important;
      margin: 0 !important;
    }
  }
}
