/* You can add global styles to this file, and also import other style files */
@import "bootstrap/scss/bootstrap";
@import "primeicons/primeicons.css";
@import "./scss/variables";
@import "./scss/ta-variables";
@import "./scss/ta-icon";
@import "./scss/ngx-ui-tour";
@import "./scss/primeng";
@import "./scss/leaflet";
@import "primeicons/primeicons.css";

body {
  font-family: $ta-font;
  .p-widget{
    font-family: $ta-font;
  }
}

.cursor-pointer {
  cursor: pointer;
}
